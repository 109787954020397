













import { Component, Vue } from 'vue-property-decorator'
import AuthStore from '@/store/AuthStore'
import DashboardLayoutDay from '@/components/common/DashboardLayoutDay.vue'
import DashboardLayoutTrip from '@/components/common/DashboardLayoutTrip.vue'
import userCollection from '@/models/user'
import companyCollection from '@/models/company'

@Component<OwnerDashboardIndex>({
  components: {
    DashboardLayoutDay,
    DashboardLayoutTrip
  }
})
export default class OwnerDashboardIndex extends Vue {
  get phoneNumber (): string {
    // return '+821039198402'
    if (!AuthStore.firebaseUser) return ''
    return AuthStore.firebaseUser.phoneNumber || ''
  }

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }

  async test (): Promise<void> {
    const doc = await userCollection.doc('cA5wNzvuy6McUqgPe3Xsdr2n8mqK').get()
    console.log(doc.data())
  }

  async test2 (): Promise<void> {
    const ref = userCollection // .where('companies', 'array-contains-any', [companyCollection.doc('ny3clsyE2hztp2NOAsxt'), companyCollection.doc('GhTVsM7S47x49k3D4nqc')])
    const sn = await ref.get()
    sn.forEach(doc => console.log(doc.data().phoneNumber))
  }
}
